<template>
  <div class="withdraw-container py-4 col-12 col-lg-12 col-xl-10 mx-auto">
    <b-tabs class="pb-3 px-0 mt-5 text-center home-pills" pills>
        <b-tab class="col-12">
            <template slot="title" class="mx-0">
            <div class=" py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab  w-100" name="withdraw">
                    Выплата
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
              <div class="my-5">
                <h1 class="page-title white-color text-center ">Вывести выигрыш</h1>
              </div>
              <div class="col-12 col-md-7 col-xl-8 px-0" :class="[!userData ? 'mx-auto' : '']">
                <div class="w-100 text-center" :class="[userData ? 'text-sm-left' : 'pt-3']">
                  <img :src="moneyIcon" alt="deposit" class="mr-3">
                  <span class="text-white choose-method-txt">Выберите платежную систему:</span>
                </div>

                  <div class="row mx-0 my-4 justify-content-around"
                      :class="[userData ? 'justify-content-sm-start' : 'pt-3']" v-if="withdrawMethods && withdrawMethods.regular">
                    <div class="col-8 col-sm-6 col-lg-4 mb-3 pl-0 mr-lg-3 withdraw-method-container"
                        v-for="(method, index) in withdrawMethods.regular" :key="index">
                      <div class="row mx-0 withdraw-method" v-on:click="changeMethod(method)">
                        <div class="col-9 px-0 method-img-area text-center">
                          <img :src="method.img" class="withdraw-method-image mx-auto"
                              :alt="method.name">
                        </div>
                        <div class="col-3 px-0 withdraw-currency-area text-center"
                            :class="[method.name == selectedMethod.name ? 'selected-method' : '']">
                          <span>RUB</span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div v-else class="row justify-content-center w-100">
                    <i class="fa fa-spinner fa-spin fa-fw my-5 white-color"></i>
                  </div>

                  <div class="row mx-0" v-if="userData">
                    <div class="col-12 px-0" v-if="selectedMethod.name">
                      <span class="hint-withdraw-method white-color">Выплата на {{ selectedMethod.name }}:</span><br>
                      <span
                          class="hint-amount gray-color">Минимальная сумма выплаты {{ selectedMethod.min_amount }} руб.</span><br>
                      <span class="hint-amount gray-color">Максимальная сумма выплаты {{ selectedMethod.max_amount }} руб. за один перевод.</span><br>
                      <span class="hint-amount gray-color" v-if="selectedMethod.time">
                        до {{ selectTime(selectedMethod.time) }}
                      </span><br>
                      <!-- <span class="hint-amount gray-color">Максимальный вывод в течение суток 50.000 рублей, остаточный выигрыш можете вывести на следующий день.</span><br> -->
                      <span class="hint-withdraw-method gray-color"> {{ selectedMethod.add_info }} </span>
                    </div>

                    <b-form class="withdraw-form my-4 col-12 col-md-11 col-lg-9 px-0">
                      <div class="input-group mb-2 mb-lg-4">
                        <div class="input-group mb-2">
                          <div class="col-3 text-lg-right px-0">
                            <label class="col-form-label mr-3 text-white">Сумма вывода</label>
                          </div>
                          <div class="col-9 px-0">
                            <input class="form-control"
                                  type="number"
                                  :min="selectedMethod.min_amount"
                                  :max="selectedMethod.max_amount"
                                  id="withdraw-amount"
                                  v-model="payload.amount"
                                  v-on:keyup="keyUp"
                                  required aria-label="amount"
                                  placeholder="Сумма в рублях">
                          </div>
                          <span  class="hint-amount text-red" v-if="withdrawinfo" style="margin-top: 20px;margin-left: 200px; color:red">{{ withdrawinfotext }}</span>
                        </div>
                      </div>
                      <div class="input-group mb-2 mb-lg-4">
                        <div class="input-group">
                          <div class="col-3 text-lg-right px-0">
                            <label class="col-form-label mr-3 text-white">{{ selectedMethod.requisites_title }}</label>
                          </div>
                          <div class="col-9 px-0">
                            <input v-mask="getSelectedMethodMask" class="form-control" aria-label="recipient"
                                  v-model="payload.recipient" type="text">
                            <p class="add-info">
                               {{ selectedMethod.add_info }}
                            </p>    
                          </div>
                        </div>
                      </div>
                      <div class="input-group mb-2 mb-lg-4" v-if="selectedMethod.additional_info && selectedMethod.additional_info.length">
                        <div class="input-group mb-2">
                          <div class="col-3 text-lg-right px-0">
                            <label class="col-form-label mr-3 text-white">Название банка</label>
                          </div>
                          <div class="col-9 px-0">
                            <select v-model="payload.bank"   class="form-control">
                              <option :value="null" disabled selected>Выбери банк</option>
                              <option v-for="(method, index) in selectedMethod.additional_info"
                                      :key="index"
                                      :value="method.code">
                                {{ method.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 tip" v-if="tipData && tipData.use && tipData.use === true ">
                        <div class="tip-block">
                          <div class="text-block">
                            <p>Хочу оставить на чай</p>
                          </div>
                          <div class="tip-type-block">
                            <div class="fin" v-for="(data, index) in tipTypesData" :key="index">
                              <label for="finButton">
                                <img :src="data.src" class="fin-icon tip-type"
                                    :class="{ 'active-type': index === activeType }"
                                    @click="tipTypeAction(data.value,index)">
                              </label>
                              <div class="text-center">
                                <span>{{data.label}}</span>
                              </div>
                              <div class="check-block" >
                                <svg class="checkmark" width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ 'active-check': index === activeType }">
                                  <path class="checkmark__check" d="M24 51.6739C25.8629 51.6739 35.5417 62.1638 40.8583 68.0867C42.7759 70.223 46.0818 70.3683 48.1455 68.3727C57.6301 59.2012 81.8373 36 84 36" stroke="#098a1b" stroke-width="10" stroke-linecap="round" />
                                </svg>
                              </div>
                            </div>
                          </div>
                          <div class="tip-amount-block">
                            <div class="smaylik-amount-block">
                              <div class="amout-smayl" v-for="(data, index) in tipAmountData" :key="index">
                                <label for="amountBtn10">
                                  <img :src="data.src" class="amount-icon"
                                      @click="tipAmountAction(tipData.amounts[index],index)"
                                      :class="{ 'active-amount': index === activeAmount }">
                                </label>
                              </div>
                            </div>
                            <div class="samyl-text-block">
                              <span>Выберите сумму для чаевых от {{ tipData.amounts[0] }}  руб․</span>
                            </div>
                            <div>
                              <input  v-model="tipAmount"
                                      v-on:keyup="tipAmountkeyUp"
                                      placeholder="Сумма на чай"
                                      class="form-control input-md rect tip-amount-val">
                            </div>
                          </div>
                        </div>
                      </div>
                      <b-row class="mt-2 mx-0">
                        <div class="col-0 col-sm-3 px-0"></div>
                        <div class="col-12 col-sm-9 px-0">
                          <div class="clearfix"></div>
                          <Alert :message="formResponse"></Alert>
                        </div>
                      </b-row>
                      <b-row class="mt-2 mx-0">
                        <div class="col-0 col-sm-3 px-0"></div>
                        <div class="col-12 col-sm-9 px-0">
                          <button class="btn btn-green w-100" type="submit" :disabled="isSubmitting"
                                  @click.stop.prevent="openModal()">
                            Вывести деньги
                          </button>
                        </div>
                      </b-row>

                    </b-form>
                  </div>
                </div>       
            </div>
        </b-tab>


      <b-tab class="col-12" v-if="withdrawMethods && withdrawMethods.vip && withdrawMethods.vip.length > 0">
        <template slot="title" class="mx-0">
          <div class=" py-0 col-12 text-center category-item px-0">
            <button class="btn pay-tab  w-100" name="vip_withdraw">
              VIP Выплата
            </button>
          </div>
        </template>

        <div class="pay-tab-body">
          <div class="my-5">
            <h1 class="page-title white-color text-center ">Вывести выигрыш</h1>
          </div>
          <div class="col-12 col-md-7 col-xl-8 px-0" :class="[!userData ? 'mx-auto' : '']">
            <div class="w-100 text-center" :class="[userData ? 'text-sm-left' : 'pt-3']">
              <img :src="moneyIcon" alt="deposit" class="mr-3">
              <span class="text-white choose-method-txt">Выберите платежную систему:</span>
              <br /><br />
              <span class="text-white choose-method-txt"><strong>Уважаемый клиент, все VIP выводы будут отправлены в течение часа.</strong></span>
            </div>

            <div class="row mx-0 my-4 justify-content-around"
                 :class="[userData ? 'justify-content-sm-start' : 'pt-3']" v-if="withdrawMethods && withdrawMethods.vip.length > 0">
              <div class="col-8 col-sm-6 col-lg-4 mb-3 pl-0 mr-lg-3 withdraw-method-container"
                   v-for="(method, index) in withdrawMethods.vip" :key="index">
                <div class="row mx-0 withdraw-method" v-on:click="changeMethod(method)">
                  <div class="col-9 px-0 method-img-area text-center">
                    <img :src="method.img" class="withdraw-method-image mx-auto"
                         :alt="method.name">
                  </div>
                  <div class="col-3 px-0 withdraw-currency-area text-center"
                       :class="[method.name == selectedMethod.name ? 'selected-method' : '']">
                    <span>RUB</span>
                  </div>
                </div>
              </div>

            </div>
            <div v-else class="row justify-content-center w-100">
              <i class="fa fa-spinner fa-spin fa-fw my-5 white-color"></i>
            </div>

            <div class="row mx-0" v-if="userData">
              <div class="col-12 px-0" v-if="selectedMethod.name">
                <span class="hint-withdraw-method white-color">Выплата на {{ selectedMethod.name }}:</span><br>
                <span
                    class="hint-amount gray-color">Минимальная сумма выплаты {{ selectedMethod.min_amount }} руб.</span><br>
                <span class="hint-amount gray-color">Максимальная сумма выплаты {{ selectedMethod.max_amount }} руб. за один перевод.</span><br>
                <span class="hint-amount gray-color" v-if="selectedMethod.time">
                        до {{ selectTime(selectedMethod.time) }}
                      </span><br>
                <!-- <span class="hint-amount gray-color">Максимальный вывод в течение суток 50.000 рублей, остаточный выигрыш можете вывести на следующий день.</span><br> -->
                <span class="hint-withdraw-method gray-color"> {{ selectedMethod.add_info }} </span>
              </div>

              <b-form class="withdraw-form my-4 col-12 col-md-11 col-lg-9 px-0">
                <div class="input-group mb-2 mb-lg-4">
                  <div class="input-group mb-2">
                    <div class="col-3 text-lg-right px-0">
                      <label class="col-form-label mr-3 text-white">Сумма вывода</label>
                    </div>
                    <div class="col-9 px-0">
                      <input class="form-control"
                             type="number"
                             :min="selectedMethod.min_amount"
                             :max="selectedMethod.max_amount"
                             id="withdraw-amount"
                             v-model="payload.amount"
                             v-on:keyup="keyUp"
                             required aria-label="amount"
                             placeholder="Сумма в рублях">
                    </div>
                    <span  class="hint-amount text-red" v-if="withdrawinfo" style="margin-top: 20px;margin-left: 200px; color:red">{{ withdrawinfotext }}</span>
                  </div>
                </div>
                <div class="input-group mb-2 mb-lg-4">
                  <div class="input-group">
                    <div class="col-3 text-lg-right px-0">
                      <label class="col-form-label mr-3 text-white">{{ selectedMethod.requisites_title }}</label>
                    </div>
                    <div class="col-9 px-0">
                      <input v-mask="getSelectedMethodMask" class="form-control" aria-label="recipient"
                             v-model="payload.recipient" type="text">
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2 mb-lg-4" v-if="selectedMethod.additional_info && selectedMethod.additional_info.length">
                  <div class="input-group mb-2">
                    <div class="col-3 text-lg-right px-0">
                      <label class="col-form-label mr-3 text-white">Название банка</label>
                    </div>
                    <div class="col-9 px-0">
                      <select v-model="payload.bank"   class="form-control">
                        <option :value="null" disabled selected>Выбери банк</option>
                        <option v-for="(method, index) in selectedMethod.additional_info"
                                :key="index"
                                :value="method.code">
                          {{ method.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-12 tip" v-if="tipData && tipData.use && tipData.use === true ">
                  <div class="tip-block">
                    <div class="text-block">
                      <p>Хочу оставить на чай</p>
                    </div>
                    <div class="tip-type-block">
                      <div class="fin" v-for="(data, index) in tipTypesData" :key="index">
                        <label for="finButton">
                          <img :src="data.src" class="fin-icon tip-type"
                               :class="{ 'active-type': index === activeType }"
                               @click="tipTypeAction(data.value,index)">
                        </label>
                        <div class="text-center">
                          <span>{{data.label}}</span>
                        </div>
                        <div class="check-block" >
                          <svg class="checkmark" width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ 'active-check': index === activeType }">
                            <path class="checkmark__check" d="M24 51.6739C25.8629 51.6739 35.5417 62.1638 40.8583 68.0867C42.7759 70.223 46.0818 70.3683 48.1455 68.3727C57.6301 59.2012 81.8373 36 84 36" stroke="#098a1b" stroke-width="10" stroke-linecap="round" />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div class="tip-amount-block">
                      <div class="smaylik-amount-block">
                        <div class="amout-smayl" v-for="(data, index) in tipAmountData" :key="index">
                          <label for="amountBtn10">
                            <img :src="data.src" class="amount-icon"
                                 @click="tipAmountAction(tipData.amounts[index],index)"
                                 :class="{ 'active-amount': index === activeAmount }">
                          </label>
                        </div>
                      </div>
                      <div class="samyl-text-block">
                        <span>Выберите сумму для чаевых от {{ tipData.amounts[0] }}  руб․</span>
                      </div>
                      <div>
                        <input  v-model="tipAmount"
                                v-on:keyup="tipAmountkeyUp"
                                placeholder="Сумма на чай"
                                class="form-control input-md rect tip-amount-val">
                      </div>
                    </div>
                  </div>
                </div>
                <b-row class="mt-2 mx-0">
                  <div class="col-0 col-sm-3 px-0"></div>
                  <div class="col-12 col-sm-9 px-0">
                    <div class="clearfix"></div>
                    <Alert :message="formResponse"></Alert>
                  </div>
                </b-row>
                <b-row class="mt-2 mx-0">
                  <div class="col-0 col-sm-3 px-0"></div>
                  <div class="col-12 col-sm-9 px-0">
                    <button class="btn btn-green w-100" type="submit" :disabled="isSubmitting"
                            @click.stop.prevent="openModal()">
                      Вывести деньги
                    </button>
                  </div>
                </b-row>

              </b-form>
            </div>
          </div>
        </div>
      </b-tab>



        <b-tab class="col-12" v-if="userData">
            <template slot="title" class="mx-0">
            <div class="tab-title py-0 col-12 text-center category-item px-0">
                <button class="btn pay-tab  w-100" name="history">
                    История
                </button>
            </div>
            </template>

            <div class="pay-tab-body">
            <div class="history" ref="history">
                <div class="history-inner">
                   <div>
                    <b-tabs class="pb-3 mt-3 px-0 text-center tab-li" pills>
                        <b-tab class="col-12">
                            <template slot="title" class="mx-0">
                            <div class=" py-0 col-12 text-center category-item px-0">
                                <button class="btn  pay-tab  w-100" name="history_withdraw">
                                    Депозит
                                </button>
                            </div>
                            </template>

                            <div class="pay-tab-body">
                            <div class="history" ref="history">
                                <div class="history-inner">
                                    <History :histories="depositHistory ? depositHistory : null " :mode="'deposit'"/>
                                </div>
                            </div>
                            </div>
                        </b-tab>
                        <b-tab class="col-12">
                            <template slot="title" class="mx-0">
                            <div class=" py-0 col-12 text-center category-item px-0">
                                <button class="btn  pay-tab  w-100" name="history_withdraw">
                                    Выплата
                                </button>
                            </div>
                            </template>

                            <div class="pay-tab-body">
                            <div class="history" ref="history">
                                <div class="history-inner">
                                    <History :histories="withdrawHistory ? withdrawHistory : null" :mode="'withdraw'"/>
                                </div>
                            </div>
                            </div>
                        </b-tab>
                    </b-tabs> 
                   </div>
                </div>
            </div>
            </div>
        </b-tab>
      </b-tabs> 
    <div class="row mx-0">
      <b-modal id="confirmWithdrawModal" size="lg" v-if="selectedMethod" v-model="confirmWithdrawModal"
               :hide-footer="true">
        <div class="col-12 px-0 text-center">
          <span class="white-color"> {{ hint }} </span>

          <div class="row mx-0 my-3">
            <div class="clearfix"></div>
            <Alert :message="popupMessages"></Alert>
          </div>

          <div class="form-group row mx-0">
            <div class="col-6 col-lg-5 mx-auto px-0">
              <button class="btn btn-green w-100" type="submit" @click.prevent="makeWithdraw()">
                Вывести
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
const moneyIcon = require('@/../public/images/icons/money-icon.png')
const watchIcon = require('@/../public/images/icons/watch-icon.png')
import Alert from '@/components/Alert'
import History from './History.vue'

export default {
  name: 'Withdraw',
  data() {
    return {
      moneyIcon,
      watchIcon,
      payload: {
        recipient: '',
        amount: '',
        bank: null
      },
      tipType:'',
      tipAmount:'',
      tipTypesData:[
        {src:require('@/../public/images/icons/tips/fin1.png'), value:1, label: "Фин.Отдел"},
        {src:require('@/../public/images/icons/tips/meneg1.png'), value:2, label: "Менеджер"},
      ],
      tipAmountData:[
        {src:require('@/../public/images/icons/tips/10.png')},
        {src:require('@/../public/images/icons/tips/50.png')},
        {src:require('@/../public/images/icons/tips/100.png')},
        {src:require('@/../public/images/icons/tips/no-tip.png')},
      ],
      activeAmount:'',
      activeType:'',
      selectedMethod: {
        name: '',
        mask: '',
        min_amount: 10,
        max_amount: 15000,
        type: '',
        time:'',
        bank: '',
        add_info: ''
      },
      isSubmitting: false,
      modalConfirmed: false,
      confirmWithdrawModal: false,
      formResponse: {},
      popupMessages: {},
      hint: '',
      withdrawinfo: false,
      withdrawinfotext: ''
    }
  },
  components: {
    Alert,
    History
  },
  computed: {
    getSelectedMethodMask() {
      if(this.selectedMethod.mask != ''){
        return typeof this.selectedMethod.mask === 'string' ? this.selectedMethod.mask : this.selectedMethod.mask.join(',').split(',');
      }
      return null;
    },
    withdrawMethods() {
      return this.$store.getters['getAvilWithdrawMethods'];
    },
    withdrawHistory () {
      return this.$store.getters.getLastWithdrawHistory
    },
    depositHistory () {
      return this.$store.getters.getDepositHistory
    },
    userData() {
      return this.$store.getters['userData']
    },
    tipData() {
      return this.$store.getters.getTipData
    },
  },
  methods: {
    changeMethod(method) {
      this.withdrawinfo = false
      if (!this.userData) {
        this.selectedMethod.name = ''
        this.selectedMethod.mask = ''
        this.selectedMethod.type = ''
        this.selectedMethod.bank = ''
        this.selectedMethod.add_info = ''
        this.selectedMethod.min_amount = 10
        this.selectedMethod.max_amount = 15000
        this.$router.push({name: 'login'})
      }

      this.selectedMethod = method
      this.isSubmitting = true;
      this.payload.bank = null;
      this.payload.recipient = '';
      this.formResponse = {};
      this.tipType = '';
      this.tipAmount = '';
      this.activeAmount = '';
      this.activeType = ''
      let elem = document.querySelector(".withdraw-form")
      if (elem !== null) {
        let position = elem.getBoundingClientRect().top + document.documentElement.scrollTop
        position = parseInt(position)
        window.scrollTo({
          top: position,
          behavior: "smooth"
        });
      }

      setTimeout(() => {
        let amountInput = document.getElementById("withdraw-amount")
        if (amountInput) {
          amountInput.focus();
        }
      }, 500)
    },
    tipAmountkeyUp(){
      this.isSubmitting = false
    },
    selectTime(time){
        if(time > 59){
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        let  formatTime = hours + " ч. ";
        if (minutes !== 0) {
          formatTime += minutes + " мин.";
        }
        return formatTime ;
        }else{
          return time + " мин.";
        }
    },
    makeWithdraw() {
      if (!this.userData) {
        return
      }
      this.formResponse = {status: 'loading'}
      this.popupMessages = {status: 'loading'}

      this.isSubmitting = true
      const formData = new FormData();
      formData.append('withdraw_amount', this.payload.amount);
      formData.append('withdraw_requisites', this.payload.recipient);
      formData.append('method', this.selectedMethod.type);
      formData.append('bank', this.payload.bank);
      formData.append('tipType', this.tipType);
      formData.append('tipAmount', this.tipAmount);


      this.axios.post(
          this.$_config.baseUrl + '/Api/newWithdraw', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(({data}) => {
        if (data.status === 'ok') {
          this.popupMessages = data
          this.formResponse = {}
          this.payload.bank = ''
          this.activeAmount = '',
          this.activeType = '',
          setTimeout(() => {
            this.payload.amount = null
            this.payload.recipient = null
            this.$root.$emit('bv::hide::modal', 'confirmWithdrawModal')
            if(data.data.enable_waiting_withdraw_modal == 1 ){
              this.$root.$emit('bv::show::modal', 'AfterWithdrawModal')
              this.countDownDate()
            }
          }, 2000)
        } else {
          this.confirmWithdrawModal = false
          this.formResponse = data
        }
        this.isSubmitting = false
      }).catch(err => {
        console.log(err)
      })
      return true
    },
    openModal() {
      this.formResponse = {}
      this.popupMessages = {}
      if (!this.userData) {
        this.formResponse = {status: 'error', msg: "Unauthorized"}
        setTimeout(() => {
          this.$router.push({name: 'login'})
        }, 500)
        return false
      }

      if (!this.selectedMethod.name) {
        this.formResponse = {status: 'error', msg: "Пожалуйста выберите платежную систему"}
        return false
      }

      if (!this.payload.amount || this.payload.amount < this.selectedMethod.min_amount
          || this.payload.amount > this.selectedMethod.max_amount) {
        this.formResponse = {status: 'error', msg: "Пожалуйста, укажите правильую сумму"}
        return false
      }

      if (!this.payload.recipient) {
        this.formResponse = {status: 'error', msg: "Fill all fields"}
        return false
      }

      this.confirmWithdrawModal = true
      this.hint = 'Подтвердите вывод на ' + this.payload.recipient + '. На сумму ' + this.payload.amount + 'Руб.'
    },
    keyUp(evt){
      this.withdrawinfo = false
      if(this.selectedMethod.type == "visacardpercent" && evt.target.value >= this.selectedMethod.min_amount){
        this.withdrawinfo = true
        this.withdrawinfotext = "Поступят "+(evt.target.value-50)+" руб, 50 руб комиссия"
      }
    },
    tipTypeAction(type,index) {
      this.tipType = type
      this.activeType = index

    },
    tipAmountAction(amount,index){
      this.isSubmitting = false
      if(typeof amount === "undefined"){
        this.tipAmount = ''
      }else{
        this.tipAmount = amount
      }
      this.activeAmount = index
    },
    countDownDate() {
      var countDownDate = new Date().getTime() + 60 * 60 * 1000;
      var x = setInterval(function () {
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        if(minutes <= 9){
          minutes = "0"+minutes;
        }
        if(seconds <= 9){
          seconds = "0"+seconds;
        }
        document.getElementById("waiting-data-area").innerHTML = minutes + ":" + seconds;
        if (distance <= 0) {
          clearInterval(x);
          window.location.reload()
        }
      }, 1000);
    }
  },
  mounted() {
    this.$store.dispatch('getWithdrawHistory')
    this.$store.dispatch('getDepositHistory')
    if (this.userData) {
      setTimeout(() => {
        let withdrawMethod = document.querySelector('.withdraw-method')
        if (withdrawMethod) {
          withdrawMethod.click();
        }
      }, 500)
    }
  }
}
</script>

<style scoped>
.watch-icon {
  width: 24px;
  height: 22px;
}

.withdrows {
  max-height: 400px;
  overflow-y: scroll;
}

.withdrows::-webkit-scrollbar {
  width: 3px;
}
.add-info{
  color: #ffffff;
  font-weight: bold;
  margin-top: 20px;
 }
.last-withdraws-container::-webkit-scrollbar-thumb,
.withdrows::-webkit-scrollbar-thumb {
  background-image: linear-gradient(#966b2d, #fce24c);
}

.last-withdraws-container {
  background-color: rgba(36, 55, 98, 0.2);
  border-radius: 10px;
  max-height: 500px;
  overflow-y: scroll;
}

.last-withdraws-container::-webkit-scrollbar {
  width: 3px;
}

.withdraw-history,
.hint-withdraw-method {
  font-size: 18px;
  font-weight: 600;
}

.withdraw-form input,
.withdraw-form select{
  border-radius: 30px;
}

.choose-method-txt,
.hint-amount,
.last-withdraw-item {
  font-size: 14px;
}

.last-withdraw-item hr {
  background-color: #132835;
}

.withdraw-method {
  background-color: white;
  border-radius: 20px;
  margin: 0 10px;
  cursor: pointer;
}

.withdraw-method-image {
  width: 70%;
  height: 45px;
  object-fit: contain;
}

.method-img-area {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 20px 0;
}

.withdraw-currency-area span {
  font-weight: 600;
  color: #2b2b2b;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

.withdraw-currency-area {
  background-color: #e7e9eb;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  line-height: 75px;
  transition: 1.5s;
}

.selected-method {
  background-color: #e4c128;
}

.selected-method span {
  color: white;
}

.withdraw-method-container {
  max-width: 250px;
}
.tip{
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.tip-block{
  width: 300px;
}
.fin-icon,
.mange-icon{
  cursor: pointer;
  transition: fill 0.3s;
  border-radius: 50%;
  object-fit: contain;
}
.samyl-text-block{
  text-align: center;
  color: #ffffff;
}
.text-block{
  margin-top: 20px;
  font-size: 20px;
}
.mange span{
  margin-right: 20px;
}
.tip-type-block{
  height: 130px;
  display: flex;
  justify-content: space-around;
  color: #ffffff;
}
.tip-type{
  width: 70px;
  height: 70px;
  transition: width 0.5s, height 0.5s;
}
.active-type{
  width: 80px;
  height: 80px;
}
.smaylik-amount-block{
  height: 70px;
  display: flex;
  justify-content: space-between;
}
.text-block p{
  text-align: center;
  color: #ffffff;
}
.amount-icon{
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  transition: width 0.5s, height 0.5s;
}
.active-amount{
  width: 60px;
  height: 60px;
}
.tip-amount-block{
  margin-top: 30px;
}

.tip-amount-val{
  margin-top: 30px;
  margin-bottom: 30px;
}
.checkmark{
  display: none;
}
.active-check{
  display: block;
}
.check-block{
  display: flex;
  justify-content: center;
}
.checkmark__check {
  stroke-dasharray: 412;
  stroke-dashoffset: 412;
  animation: anim 1s ease forwards 0.3s;
}

@keyframes anim {
  to {
    stroke-dashoffset: 0;
  }
}
.pay-tab{
      color: #fff!important;
  }
  .pay-tab:hover{
    text-decoration: underline;
  }
  .nav-pills .nav-link.active .pay-tab {
    width: 25%;
    background-color: #f6d307d9;
  }
  
  .pay-tab:active,
  .pay-tab:focus {
      outline: none; 
      box-shadow: none!important;
  }

  @media (max-width: 992px) {
      #paymentModal{
          padding-left: 0 !important;
      }
  }
  @media (max-width:402px) {
      .pay-tab{
        font-size: 13px;
      }
  }
  @media (max-width:338px) {
      .pay-tab{
        font-size: 11px;
      }
      .nav-pills{
      height: 43px;
  }
}
</style>
  
<style>
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
        color: aliceblue;
        background-color: unset!important;
    }
    .nav-item{
        width: 33%;
    }
    .nav-pills .nav-link {
        padding: 0.5rem;
    }
    .nav-pills{
        height: 47px;
    }
    @media (max-width:338px) {
        .nav-pills{
        height: 43px;
    }
    }
</style>


