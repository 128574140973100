<template>
    <div class="profile-container col-12 mt-3 mt-lg-5 mx-auto px-0">
        <div class="row mx-0">

            <div class="wheel-access transparentBg py-3 px-1 px-md-5" v-if="wheelStats">
                <h4 class="white-color text-center font-weight-bold">Доступ колеса:</h4>

                <div class="progress my-1">
                    <div class="progress-bar progress-bar-striped"
                         v-bind:style="{ width: wheelStats ? wheelStats.progressPercent : 0 + '%' }"></div>
                    <div class="progress-text">
                        <span class="text-white">{{wheelStats.progressPercent}} %</span>
                    </div>
                </div>

                <div class="wheel-access-desc my-4">
                    <span class="orange-color text-center d-block font-weight-bold">
                        У Вас - {{wheelStats.lastMonthDeposit}} / {{wheelStats.profitBorder}}
                    </span>

                    <span class="white-color d-block mt-2 font-weight-bold">
                      Колесо Фортуны активируется при пополнении от {{wheelStats.profitBorder}} руб. в течение 30 дней.
                     </span>
                    <span class="white-color d-block mt-2">
                        К примеру: для доступа к Колесу Фортуны сегодня, сумма Ваших пополнений за 2019-10-25 14:50:59
                        до 2019-11-25 14:50:59 должна быть больше {{wheelStats.profitBorder}} руб.
                    </span>
                </div>
            </div>

            <div class="user-info transparentBg text-white font-weight-bold mt-5 mt-lg-3" v-if="userData">
                <b-list-group>
                    <b-list-group-item>
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right"><span>Логин:</span></div>
                            <div class="col-6 py-2"><span>{{userData.login}}</span></div>
                        </div>
                    </b-list-group-item>

                    <b-list-group-item>
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right"><span>Ваше имя:</span></div>
                            <div class="col-6 py-2" v-if="userData.name">
                                <span>{{userData.name}}</span>
                            </div>
                            <div class="col-6 py-2" v-else>
                                <form class="w-100 name-form">
                                    <Alert :message="updateNameResponse"></Alert>
                                    <fieldset class="form-group row m-0">
                                        <div class="col-12 px-0">
                                            <input type="text" placeholder="Ваше имя*" required aria-required="true"
                                                   v-model="usersName" class="form-control mb-2">
                                            <b-button class="btn btn-orange w-100 border-0 btn-secondary"
                                                      @click.stop.prevent="updateUsersName">
                                                Подтвердить
                                            </b-button>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </b-list-group-item>

                    <b-list-group-item>
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right white-color">Тел. номер:</div>
                            <div class="col-6 py-2">
                                <span>
                                    <input type="text" class="user-phone" disabled :value="userData.phone"
                                           v-mask="'+#(###)-###-##-##'">
                                </span><br>

                                <div class="col-12 col-lg-8 mt-2 px-0" v-if="!userData.phone_confirmed">
                                    <b-button @click.prevent="confirmPhone" class="btn-orange w-100 border-0">
                                        Подтвердить
                                    </b-button>
                                </div>

                            </div>
                        </div>
                    </b-list-group-item>

                    <b-list-group-item>
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right">Баланс:</div>
                            <div class="col-6 py-2">
                                <span class="orange-color">{{userData.balance}} Руб</span><br>

                                <div class="col-12 col-lg-8 mt-2 px-0">
                                    <router-link class="btn white-color btn-orange w-100 border-0"
                                                 :to="{ path: '/deposit' }">
                                        Пополнить
                                    </router-link>
                                </div>

                            </div>
                        </div>
                    </b-list-group-item>

                    <b-list-group-item>
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right">Пароль:</div>
                            <div class="col-6 py-2">
                                <span>*******</span><br>
                                <b-button @click.prevent="changePassword" class="btn-gray mt-2">
                                    Изменить
                                </b-button>
                            </div>
                        </div>
                    </b-list-group-item>

                    <b-list-group-item v-bind:class="{'border-0' : userData.verified == 1}">
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right">E-mail:</div>
                            <div class="col-6 py-2">
                                <span>{{userData.email}}</span><br>
                                <div class="col-12 col-lg-8 mt-2 px-0" v-if="userData.email_confirmed == 0">
                                    <b-button @click.prevent="confirmEmail" class="btn-orange w-100 border-0">
                                        Подтвердить
                                    </b-button>
                                </div>

                            </div>
                        </div>
                    </b-list-group-item>
                    <b-list-group-item class="border-0">
                        <div class="row">
                            <div class="col-6 py-2 left-side text-right">Верификация</div>
                            <div class="col-6 py-2">
                                <div class="col-12 col-lg-8 px-0">
                                    <span class="d-inline-block user-verified-text">
                                      <span v-html="accountIdentifyStatus"></span>
                                      <br>
                                      <small v-if="accountIdentifyStatusDesc"
                                             v-html="accountIdentifyStatusDesc"></small>
                                    </span>
                                    <button type="button"
                                            v-if="showIdentificationButton"
                                            @click.prevent="showIdentifyModal"
                                            v-b-modal="'IdentifyModalContainer'"
                                            class="btn-orange w-100 border-0">
                                        Пройти верификацию
                                    </button>
                                </div>

                            </div>
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>
        </div>

        <ConfirmPhoneViaCall :userData="userData"
                             v-if="mainSettings && mainSettings.phoneActionsMethod==='call'"></ConfirmPhoneViaCall>
        <ConfirmPhone :userData="userData" v-else></ConfirmPhone>
        <ConfirmEmail :userData="userData"></ConfirmEmail>
        <ChangePassword :userData="userData"></ChangePassword>
        <ConfirmAccount :user-data="userData" v-if="userData && userData.verified !== 1"
                        @changed="updateState()"></ConfirmAccount>
    </div>
</template>

<script>
  import ConfirmPhone from '@/components/user/profile/ConfirmPhone'
  import ConfirmPhoneViaCall from '@/components/user/profile/ConfirmPhoneViaCall'
  import ConfirmEmail from '@/components/user/profile/ConfirmEmail'
  import ChangePassword from '@/components/user/profile/ChangePassword'
  import ConfirmAccount from "@/components/user/profile/ConfirmAccount"
  import Alert from '@/components/Alert'

  export default {
    components: {
      ConfirmPhone,
      ConfirmEmail,
      ChangePassword,
      ConfirmPhoneViaCall,
      ConfirmAccount,
      Alert
    },
    data() {
      return {
        identityData: null,
        accountIdentifyStatus: null,
        accountIdentifyStatusDesc: null,
        showIdentificationButton: false,
        updateNameResponse: null,
        usersName: ''
      }
    },
    async created() {
      await this.$store.dispatch('fetchUserIdentityData')
    },
    computed: {
      mainSettings() {
        return this.$store.getters.getMainSettings
      },
      userData() {
        return this.$store.getters['userData']
      },
      wheelStats() {
        return this.$store.getters['getWheelStats']
      },
      userIdentityData() {
        return this.$store.getters.identityData
      }
    },
    updated() {
      this.identityData = this.$store.getters.identityData
    },
    mounted() {
      setTimeout(() => {
        if (!this.userData) {
          return this.$router.push({name: 'home'})
        }
      }, 2500)
      this.identityData = this.$store.getters.identityData
      this.setAccountIdentifyStatus()
      this.$store.dispatch('getWheelStats')
    },
    methods: {
      updateUsersName() {
        this.updateNameResponse = {status: 'loading'}
        let queryString = `name=${this.usersName}`
        this.axios.post(
          this.$_config.baseUrl + '/Api/updateUserName/', queryString,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({data}) => {
          this.updateNameResponse = data

          if (data.status !== "error") {
            this.$store.dispatch('fetchUserData')
            setTimeout(() => {
              this.updateNameResponse = null
            }, 5000)
          }
        }).catch(() => {
          this.updateNameResponse = {
            status: 'error', msg: 'Ошибка при отправке запроса, повторите позже'
          }
        })
      },
      showIdentifyModal() {
        return this.$root.$emit('bv::show::modal', 'confirmPassportModal')
      },
      changePassword() {
        this.$root.$emit('bv::show::modal', 'changePasswordModal')
      },
      confirmPhone() {
        this.$root.$emit('bv::show::modal', 'confirmPhoneModal')
      },
      confirmEmail() {
        this.$root.$emit('bv::show::modal', 'confirmEmailModal')
      },
      setAccountIdentifyStatus() {
        if (!this.userData) {
          return
        }
        let accountVerified = this.userData.verified
        let identifyConfirmedStatus = false

        if (this.identityData) {
          identifyConfirmedStatus = this.identityData.approved
        }
        this.showIdentificationButton = false
        this.accountIdentifyStatus = null
        this.accountIdentifyStatusDesc = null

        switch (accountVerified) {
          case 0:
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<span class="text-danger">Аккаунт не верифицирован</span>'
            if (identifyConfirmedStatus === 2) {
              const reason = this.identityData.reject_reason
              this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
            }
            if (identifyConfirmedStatus === 0) {
              this.showIdentificationButton = false
              this.accountIdentifyStatus = '<span class="text-warning">Данные в обработке</span>'
            }
            break;
          case -1:
            this.showIdentificationButton = true
            this.accountIdentifyStatus = '<strong class="text-danger">Необхадимо пройти верификацию !</strong>'

            if (identifyConfirmedStatus === 0) {
              this.showIdentificationButton = false
              this.accountIdentifyStatus = '<span class="text-warning">Данные в обработке</span>'
            }
            if (identifyConfirmedStatus === 2) {
              const reason = this.identityData.reject_reason
              this.showIdentificationButton = true
              this.accountIdentifyStatus = '<span class="text-warning">Получено запрос на верификацию</span>'
              this.accountIdentifyStatusDesc = `<span class="text-danger">Верификация отказано - ${reason}</span>`
            }
            break;
          case 1:
            this.accountIdentifyStatus = '<span class="text-success">Аккаунт верифицирован</span>'
            break;
        }
      },
      updateState() {
        this.identityData = this.$store.getters.identityData
        this.setAccountIdentifyStatus()
      }
    },
    watch: {
      identityData: {
        handler: function (newValue) {
          if (newValue) {
            this.setAccountIdentifyStatus()
          }
        },
        deep: true
      },
      userData: {
        handler: function (data) {
          if (data) {
            this.setAccountIdentifyStatus()
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
    .name-form input {
        border-radius: 20px !important;
        height: 35px;
        border: none;
    }

    .name-form input:focus {
        box-shadow: 0 0 10px 3px rgba(145, 157, 243, 0.5) !important;
    }

    .progress-text {
        position: absolute;
        left: 50%;
        font-size: 22px;
        font-weight: 600;
    }

    .progress {
        background-color: #0A1119;
        height: 40px;
    }

    .progress-bar {
        background-color: #6b52ad;
    }

    .progress-bar-striped {
        background-color: #141B23;
        background-size: 7px 7px;
    }

    .wheel-access span {
        font-size: 14px;
    }

    .transparentBg {
        width: 100%;
        background-color: rgba(36, 55, 98, 0.2);
        border-radius: 15px;
    }

    .list-group-item {
        background: transparent;
        border-bottom: 1px solid #1c3142;
        padding-top: 0;
        padding-bottom: 0;
    }

    .left-side {
        border-right: 1px solid #1c3142;
    }

    .wheel-access,
    .user-info .list-group {
        border: 1px solid #1c3142;
        border-radius: 15px;
        word-break: break-word;
    }

    .user-phone {
        background: transparent;
        color: white;
        border: none;
    }

    @media (min-width: 320px) and (max-width: 768px) {
        .user-info {
            overflow-x: scroll;
        }
    }
</style>
