<template>
    <div id="app">
        <div class="mx-auto main-container px-0 px-0">
            <the-header></the-header>
            <div class="content-container" :class="[isGamePage ? 'gameBg' : '']">
                <router-view></router-view>
                <main-features v-if="!isGamePage"></main-features>
            </div>
            <the-footer v-if="!isGamePage"></the-footer>
        </div>
        <FlashMessages></FlashMessages>
        <popup></popup>
        <AliveGamePopup></AliveGamePopup>
        <AfterWithdrawModal></AfterWithdrawModal>
    </div>
</template>

<script>
    import TheHeader from '@/components/TheHeader'
    import TheFooter from '@/components/TheFooter'
    import MainFeatures from '@/components/MainFeatures'
    import Popup from '@/components/Popup'
    import FlashMessages from '@/components/FlashMessages'
    import AliveGamePopup from "./components/games/AliveGamePopup";
    import AfterWithdrawModal from "./components/payment/AfterWithdrawModal"

    export default {
        name: 'app',
        components: {
            TheHeader,
            TheFooter,
            FlashMessages,
            Popup,
            MainFeatures,
            AliveGamePopup,
            AfterWithdrawModal
        },
        computed: {
            isGamePage() {
                return this.$route.name === 'TheGame' || this.$route.name === 'TheLiveGame'
            }
        },
        methods :{
            isMobile() {
                return this.$_config.environment.mobile
            },
        },
        watch: {
            $route() {
                let chatBlock = document.getElementById("tawkchat-container");
                if (chatBlock) {
                    if (this.isGamePage && this.isMobile()) {
                        chatBlock.style.display = "none"
                    } else {
                        chatBlock.style.display = "block"
                    }
                }

            }
        }
    }
</script>

