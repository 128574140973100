<template>
  <b-modal id="AfterWithdrawModal" size="md" :hide-footer="true" :hide-header="true" class="after-withdraw-modal">
    <div class="row mx-0">
      <h4 class="text-white mx-auto my-3">Подтверждение вывода</h4>
      <a class="text-white cursor-pointer" @click.prevent="closeModal">X</a>
    </div>
    <div class="col-xs-12">
        <span class="text-block text-white">
            Для подтверждения вывода средств вам необходимо связаться с нашим финансовым отделом. Обратите внимание, что в процессе обработки вывода вам необходимо оставаться на связи.<span
            id="countDownText">После подтверждения вывод будет осуществлен в течение
            <span id="waiting-data-area" style="font-size: 18px;color: #9a0807;">60</span>
            минут.
          </span>
        </span>
      <div class="row w-100 mx-0">
        <div class="col-6 text-center" v-if="getFinSupportTgLinks">
          <a :href="getFinSupportTgLinks" target="_blank" class="withdraw-support-link">
            <img src="@/../public/images/withdraw-support-fin.svg" class="withdraw-support-icon"> <br/>
            <span class="withdraw-support-text">Фин.отдел Telegram</span>
          </a>
        </div>
        <div class="col-6 text-center">
          <a class="withdraw-support-link" @click.prevent="openChat">
            <img src="@/../public/images/withdraw-support-tech.svg" class="withdraw-support-icon"><br/>
            <span class="withdraw-support-text">Фин.отдел онлайн-чат</span>
          </a>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'AfterWithdrawModal',
  computed: {
    getFinSupportTgLinks() {
      return this.$store.getters.getSocialLinks['fin_support']
    }
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'AfterWithdrawModal')
      window.location.reload()
    },
    openChat() {
      window.open("https://tawk.to/chat/6799fb443a8427326076a9da/1iionr1j0", "_blank", "width=600,height=600");
    }
  }
}
</script>

<style scoped>
.withdraw-support-icon {
  width: 50px;
  margin: 15px 0;
}

.withdraw-support-link {
  color: white !important;
  cursor: pointer;
}
</style>
